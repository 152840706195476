<template>
  <div>
    <h1>{{ requirement.properties.display_id }}</h1>
    <span v-if="requirement.parent && requirement.parent.id">
      <b-link :to="`${requirement.parent.id}`"
              :title="requirement.parent.object_text"
      >{{ requirement.parent.display_id }}</b-link>
      /
    </span>
    <span> {{ requirement.properties.display_id }} </span>
    <span v-for="(cr, index) in requirement.children" :key="cr.id">
      <span v-if="index === 0">/</span>
      <b-link :to="`${cr.id}`" :title="cr.object_text"> {{ cr.display_id }}</b-link>
      <span v-if="index < requirement.children.length - 1">,</span>
    </span>
    <hr>
    <!-- Display ID, Section # / Sequence #, Object Text, Priority -->
    <!-- VCRM - 2nd Tab -->
    <!-- 3rd Tab - Trace -->
    <!-- 4th Tab - Issues -->
    <!-- 5th Tab - Test -->
    <!-- 6th Tab - Entities / Parse Result -->
    <b-tabs
      pills
      vertical
      nav-class="nav-left"
    >
      <!-- General Tab -->
      <b-tab active>
        <template #title>
          <font-awesome-icon :icon="['fas', 'tasks']" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>

        <RequirementGeneral />
      </b-tab>
      <!-- ./General Tab -->

      <!-- VCRM Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'table']" class="mr-50" />
          <span class="font-weight-bold">VCRM</span>
        </template>

        <b-card>
          <RequirementVCRM />
        </b-card>
      </b-tab>
      <!-- ./VCRM Tab -->

      <!-- Trace (Trace Backward) Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'search']" class="mr-50" />
          <span class="font-weight-bold">Trace</span>
        </template>

        <b-card>
          <RequirementTraceBackward />
        </b-card>
      </b-tab>
      <!-- ./Trace (Trace Backward) Tab -->

      <!-- Coverage (Trace Forward) Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'search']" class="mr-50" />
          <span class="font-weight-bold">Coverage</span>
        </template>

        <b-card>
          <RequirementTraceForward />
        </b-card>
      </b-tab>
      <!-- ./Coverage (Trace Forward) Tab -->

      <!-- Issues Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'spinner']" class="mr-50" />
          <span class="font-weight-bold">Issues</span>
        </template>

        <b-card no-body>
          <RequirementIssues />
        </b-card>
      </b-tab>
      <!-- ./Issues Tab -->

      <!-- Tests Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'vials']" class="mr-50" />
          <span class="font-weight-bold">Tests</span>
        </template>

        <b-card no-body>
          <RequirementTests />
        </b-card>
      </b-tab>
      <!-- ./Tests Tab -->

      <!-- Entities Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'project-diagram']" class="mr-50" />
          <span class="font-weight-bold">Entities</span>
        </template>

        <b-card no-body>
          <RequirementEntities />
        </b-card>
      </b-tab>
      <!-- ./Entities Tab -->

      <!-- History Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'history']" class="mr-50" />
          <span class="font-weight-bold">History</span>
        </template>

        <b-card>
          <RequirementHistory />
        </b-card>
      </b-tab>
      <!-- ./History Tab -->

      <!-- Metadata Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'tags']" class="mr-50" />
          <span class="font-weight-bold">Additional Attributes</span>
        </template>

        <b-card>
          <requirement-additional-attributes />
        </b-card>
      </b-tab>
      <!-- ./Metadata Tab -->

      <!-- Debug Tab -->
      <b-tab lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'bug']" class="mr-50" />
          <span class="font-weight-bold text-danger">Debug</span>
        </template>

        <b-card>
          <RequirementDebug />
        </b-card>
      </b-tab>
      <!-- ./Debug Tab -->
      <!-- Permissions Tab -->
      <b-tab v-if="auth.roles.includes('administrator')" lazy>
        <template #title>
          <font-awesome-icon :icon="['fas', 'lock']" class="mr-50" />
          <span class="font-weight-bold">Permissions</span>
        </template>

        <b-card>
          <Permissions title="Permissions" :node-name="requirement.properties.display_id" node-type="Requirement" :node-id="requirement.properties.id" />
        </b-card>
      </b-tab>
      <!-- ./Permissions Tab -->

    </b-tabs>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import RequirementDebug from '@/views/Requirement/TabbedContent/Debug.vue'
import RequirementGeneral from '@/views/Requirement/TabbedContent/General.vue'
import RequirementHistory from '@/views/Requirement/TabbedContent/History.vue'
import RequirementIssues from '@/views/Requirement/TabbedContent/Issues.vue'
import RequirementTests from '@/views/Requirement/TabbedContent/Tests.vue'
import RequirementTraceForward from '@/views/Requirement/TabbedContent/TraceForward.vue'
import RequirementTraceBackward from '@/views/Requirement/TabbedContent/TraceBackward.vue'
import RequirementVCRM from '@/views/Requirement/TabbedContent/VCRM.vue'
import RequirementEntities from '@/views/Requirement/TabbedContent/Entities.vue'
import RequirementAdditionalAttributes from '@/views/Requirement/TabbedContent/AdditionalAttributes.vue'
import Permissions from '@/components/Permissions/Permissions.vue'

export default {
  name: 'Requirement',
  components: {
    RequirementDebug,
    RequirementGeneral,
    RequirementHistory,
    RequirementIssues,
    RequirementTests,
    RequirementTraceForward,
    RequirementTraceBackward,
    RequirementVCRM,
    RequirementEntities,
    RequirementAdditionalAttributes,
    Permissions,
  },
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
      auth: state => state.auth,
    }),
  },
  beforeCreate() {
    const reqId = this.$route.params.requirementId
    this.$store.dispatch('requirements/selectRequirement', reqId)
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        const reqId = this.$route.params.requirementId
        this.$store.dispatch('requirements/selectRequirement', reqId)
      },
    )
  },
  mounted() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  beforeDestroy() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
  },
}
</script>
