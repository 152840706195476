<template>
  <div style="background-color: rgba(0, 0, 0, 0.1)">
    <!--Has {{ requirement.direct_components.length }} associated components.-->
    <div v-for="(comp, index) in requirement.direct_components" :key="index">
      <issue-entity-card :entity="comp" />
    </div>
    <div v-if="requirement.direct_components.length === 0">
      <em>No associated entities...</em>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IssueEntityCard from '@/components/Issues/IssueEntityCard.vue'

export default {
  name: 'RequirementEntities',
  components: {
    IssueEntityCard,
  },
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
    }),
  },
}
</script>
