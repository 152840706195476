<template>
  <div>
    <IssuesTable :items="issueProps" @openIssue="issue => issueModalView(issue.id, fetchRequirementIssues)" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IssuesTable from '@/components/Issues/IssuesTable.vue'

export default {
  name: 'RequirementIssues',
  components: {
    IssuesTable,
  },
  props: {
    issueProps: {
      required: false,
      type: Array,
      default: () => [],
    },
    isSpec: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    issueOpen: false,
  }),
  computed: {
    ...mapState({
      requirement_id: state => state.requirements.selected_requirement.properties.id,
    }),
  },
  mounted() {
    this.fetchRequirementIssues()
  },
  methods: {
    fetchRequirementIssues() {
      if (!this.isSpec) {
        this.$http
          .get(`/api/v2/requirement/${this.requirement_id}/issues`)
          .then(({ data }) => {
            console.log(data)
            this.issueProps = data
          })
          .catch(e => console.error(e))
      }
    },
    openIssue(issue) {
      this.$store
        .dispatch('issues/selectIssue', issue.id)
        .then(() => {
          this.issueOpen = true
        })
    },
    updateTable() {
      this.issueOpen = false
      this.fetchRequirementIssues()
    },
  },
}
</script>
