<template>
  <div class="p-50">
    <requirement-trace-form
      ref="traceForm"
      :requirement-ids="[$route.params.requirementId]"
      :forward="true"
    />
  </div>
</template>

<script>
import RequirementTraceForm from '@/components/Forms/RequirementTraceForm.vue'

export default {
  name: 'RequirementTraceForward',
  components: {
    RequirementTraceForm,
  },
}
</script>
