<template>
  <requirement-history />
</template>

<script>

import requirementHistory from '@/views/RequirementsTableLegacy/forms/History.vue'

export default {
  name: 'RequirementHistory',
  components: {
    requirementHistory,
  },
}
</script>
