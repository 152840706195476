<template>
  <pre>{{ requirement }}</pre>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RequirementDebug',
  computed: {
    ...mapState({
      requirement: state => state.requirements.selected_requirement,
    }),
  },
}
</script>
