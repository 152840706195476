<template>
  <div>
    <b-form>
      <b-form-group>
        <LabelWithHoverIcon
          label-string="Verification Method"
          popover-text="The Verification Method this Requirement should be related to."
        />
        <b-form-select
          v-model="verification_method"
          :options="verificationMethods"
        />
        <p class="font-small-3 ml-1 mt-75">
          {{ verification_method_description }}
        </p>
      </b-form-group>
      <b-form-group>
        <LabelWithHoverIcon
          label-string="Verification Statement"
          popover-text="The Verification Statement this Requirement should be related to."
        />
        <tip-tap-editor
          id="txtVerificationStatement"
          v-model="verification_statement"
          placeholder="Explain the verification..."
        />
      </b-form-group>
      <div class="float-right mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          variant="outline-secondary"
          :disabled="!pendingChanges"
          @click="resetForm"
        >
          Reset
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          :disabled="!pendingChanges"
          @click="updateVCRM"
        >
          Save Changes
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import LabelWithHoverIcon from '@/components/Forms/LabelWithHoverIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TipTapEditor from '@/components/Forms/TipTapEditor/TipTapEditor.vue'

export default {
  name: 'RequirementVCRM',
  directives: {
    Ripple,
  },
  components: {
    TipTapEditor,
    LabelWithHoverIcon,
  },
  data: () => ({
    vcrm: [],
    verification_method: null,
    verification_statement: '',
    original_verification_method: null,
    original_verification_statement: '',
  }),
  computed: {
    ...mapState({
      requirement_id: state => state.requirements.selected_requirement.properties.id,
      requirement: state => state.requirements.selected_requirement,
    }),
    ...mapGetters({
      verificationMethods: 'constants/qualificationMethods',
    }),
    verification_method_description() {
      const selectedVerificationMethod = this.verificationMethods.find(({ value }) => value === this.verification_method)
      if (!selectedVerificationMethod) { return '' }
      return selectedVerificationMethod.description
    },
    pendingChanges() {
      return this.verification_method !== this.requirement.properties.verification_method
        || this.verification_statement !== this.requirement.properties.verification_statement
    },
  },
  watch: {
    requirement(newVal) {
      console.log(`watch requirement ${newVal}`)
      this.setStoredData(newVal)
    },
  },
  mounted() {
    console.log('mounted VCRM.vue')
    this.setStoredData(this.requirement)
  },
  methods: {
    setStoredData(req) {
      // Sets the prefilled data
      // Update on every change of requirement
      this.verification_method = req.properties.verification_method
      this.verification_statement = req.properties.verification_statement
      console.log(`Set verification statement: ${this.verification_statement}`)
    },
    updateVCRM() {
      const payload = {
        verification_method: this.verification_method,
        verification_statement: this.verification_statement,
      }
      const modelId = this.$store.state.model.id
      this.$http
        .patch(`/api/v2/requirements/${this.requirement_id}`, payload, { params: { model: modelId } })
        .then(() => {
          this.$store
            .dispatch('requirements/reselectRequirement')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'VCRM Updated',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
            })
        })
    },
    resetForm() {
      if (this.pendingChanges) {
        this.$bvModal
          .msgBoxConfirm(
            'You have unsaved changes, are you sure you wish to continue?',
            {
              title: 'Unsaved changes',
              okVariant: 'danger',
              okTitle: 'Yes, discard changes',
              cancelTitle: 'Keep editing',
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            },
          )
          .then(confirmed => {
            if (confirmed) {
              this.verification_method = this.requirement.properties.verification_method
              this.verification_statement = this.requirement.properties.verification_statement
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
#txtVerificationStatement .ql-editor {
  min-height: 8rem !important;
  max-height: 20rem !important;
}
</style>
